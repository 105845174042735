import React from "react"
import IntegrationTemplate from "../../templates/integration"
import * as Ui from "components/ui"
import * as Sections from 'components/sections'
import * as Layout from 'components/layout'
import IntegrationStorageContent from 'components/content/integration-storage'
import { meta } from '../../configs/storage-providers';
const ContentPage = () => (
  <IntegrationTemplate
    key="dropbox"
    name="Dropbox"
    shortDescription="Save your backups on Dropbox."
    website={"https://dropbox.com/"}
    links={[{text: 'Documentation', url: '/docs'}]}
    category="Storage"
    logo="dropbox-ico.png"
    metaTitle={meta.title.replace('##provider##', 'Dropbox')}
    metaDescription={meta.description.replace('##provider##', 'Dropbox')}
  >
    <IntegrationStorageContent
      keyRef="dropbox"
      service="Dropbox"
      provider="Dropbox"
      learnTags={['dropbox']}
      contentBlocks={[{title: 'Minimal Dropbox access is needed', text: 'When you connect SimpleBackups to your Dropbox account, SimpleBackups is only given access to its own application folder. Meaning that access is only limited to that folder on Dropbox and not your whole storage.\n'}]}
    />
  </IntegrationTemplate>
)

export default ContentPage
